import React from 'react'
import { Box } from 'reflexbox'
import SbEditable from 'storyblok-react'
import classnames from 'classnames'

import Image from 'src/components/UI/Image/Image'
import LinkComponent from 'src/components/LinkComponent/LinkComponent'

import styles from './ImageModule.module.scss'
import { Props } from './types'

const ImageModule = ({ blok }: Props) => {
  const { image, text, source, ratio } = blok

  let aspectRatio = ''
  if (ratio === '3:2') {
    aspectRatio = styles.wide
  }
  if (ratio === '1:1') {
    aspectRatio = styles.square
  }
  if (ratio === '4:5') {
    aspectRatio = styles.portrait
  }

  const wrapperStyles = classnames(styles.wrapper, aspectRatio)
  return (
    <SbEditable content={blok}>
      <Box className={wrapperStyles}>
        {image?.filename && (
          <Image src={image.filename} sizes={'100vw'} alt={image.title} />
        )}
      </Box>
      {(text || source?.length > 0) && (
        <Box marginTop={2} as="span" className={styles.text}>
          {text}
          {source?.length > 0 && (
            <span>
              {` (Source: `}
              {source[0]?.slug ? (
                <LinkComponent to={source[0].slug}>
                  {source[0].title}
                </LinkComponent>
              ) : (
                source[0].title
              )}
              {`)`}
            </span>
          )}
        </Box>
      )}
    </SbEditable>
  )
}

export default ImageModule
