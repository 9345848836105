import React from 'react'
import { Flex, Box } from 'reflexbox'
import { FacebookShareButton, TwitterShareButton } from 'react-share'
import isNode from 'is-node'

import LinkComponent from 'src/components/LinkComponent/LinkComponent'
import { ArrowLeft } from 'src/components/UI/Elements/Icon'

import * as styles from './ShareSection.module.scss'
import { Props } from './types'

const ShareSection = ({ extraLink }: Props): JSX.Element => {
  let absoluteUrl = ''

  if (!isNode && window && window.location) {
    absoluteUrl = window.location.href
  }
  return (
    <Flex
      width={1}
      flexDirection={['column', null, null, 'row']}
      className={styles.shareSection}
      justifyContent="flex-end"
      alignItems="center"
    >
      {extraLink?.url && extraLink?.text ? (
        <Flex
          width={[1, null, null, 1 / 2]}
          height={['100px', null, null, '120px']}
          alignItems="center"
          className={styles.extraButton}
        >
          <LinkComponent to={extraLink.url}>
            <ArrowLeft color="#FFFFFF" />
            <Box marginLeft={4} marginBottom={2} as="span">
              {extraLink.text}
            </Box>
          </LinkComponent>
        </Flex>
      ) : null}
      <Flex
        width={[1, null, null, 1 / 2]}
        height={['100px', null, null, '120px']}
        paddingX={[6, null, null, 15]}
        className={styles.share}
        alignItems="center"
      >
        <Flex
          flexWrap="wrap"
          alignItems="center"
          marginBottom={[2, null, null, 2]}
        >
          <Box marginRight={[2, null, null, 8]}>{'Share this article:'}</Box>
          <Flex>
            <FacebookShareButton
              url={absoluteUrl}
              hashtag={'#polarmusicprize'}
              className={styles.shareButton}
              title="Share this article on Facebook"
            >
              {'Facebook'}
            </FacebookShareButton>

            <Box marginX={[1, null, null, 4]}>{'|'}</Box>

            <TwitterShareButton
              url={absoluteUrl}
              className={styles.shareButton}
              title="Share this article on Twitter"
            >
              {'Twitter'}
            </TwitterShareButton>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default ShareSection
