import React from 'react'
import { Box } from 'reflexbox'

import Components from 'src/storyblok/Components'
import Container from 'src/components/UI/Grid/Container'
import Row from 'src/components/UI/Grid/Row'
import Column from 'src/components/UI/Grid/Column'

const Content = ({ content }: Props) => {
  return (
    <React.Fragment>
      <Container overflow="hidden">
        <Row flexDirection="column" justifyContent="center" alignItems="center">
          {content &&
            content.map((blok: any) => {
              const { component, images, _uid } = blok
              const noGridComponents = [
                'banner',
                'laureates_grid',
                'nomination_form',
                'title_and_text',
                'members',
                'full_width_image',
                'quote',
                'highlights',
              ]
              const isTextMedia = component === 'text_and_media'

              let bleedFirst = false
              if (isTextMedia) {
                if (images && images[0] && images[0].bleed) {
                  bleedFirst = true
                }
              }
              return (
                <Column
                  key={_uid}
                  width={
                    noGridComponents.includes(component)
                      ? 1
                      : [1, null, null, 10 / 12]
                  }
                  paddingTop={
                    isTextMedia
                      ? bleedFirst
                        ? [0, null, null, 20]
                        : [13, null, null, 20]
                      : 0
                  }
                  paddingBottom={isTextMedia ? [13, null, null, 20] : 0}
                >
                  <Box
                    marginX={
                      noGridComponents.includes(component)
                        ? ['-24px', null, null, '-24px']
                        : 0
                    }
                  >
                    {React.createElement(Components(component), {
                      key: _uid,
                      blok: blok,
                      gridSpace: 1,
                      imageColumnWidth: 5,
                      gridWidth: 10 / 12,
                    })}
                  </Box>
                </Column>
              )
            })}
        </Row>
      </Container>
    </React.Fragment>
  )
}

export default Content
