import { StoryblokLink } from 'src/globalTypes/types'

export const getLinkAttributes = (link: StoryblokLink) => {
  const title = link?.title ? link.title : ''
  let url = ''

  if (link?.url) {
    url = link?.url?.cached_url
      ? link.url.cached_url
      : link?.url?.email
      ? `mailto:${link.url.email}`
      : ''
  }
  if (link?.slug) {
    url = link.slug
  }
  return {
    url,
    title,
  }
}
