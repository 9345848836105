import React from 'react'
import { Flex } from 'reflexbox'
import SbEditable from 'storyblok-react'
import classnames from 'classnames'
// import { use1s00vh } from 'react-div-100vh'

import BigCtaButton from 'src/components/UI/Buttons/BigCtaButton/BigCtaButton'
import Components from 'src/storyblok/Components'
import PolarCircle from 'src/components/UI/Elements/PolarCircle/PolarCircle'

import styles from './Hero.module.scss'
import { Props } from './types'

const Hero = ({ blok }: Props) => {
  const { content, button } = blok

  // const height = use100vh()

  const wrapperClassName = classnames(
    styles.wrapper,
    button?.length > 0 ? styles.hasButton : ''
  )
  return (
    <SbEditable content={blok}>
      <Flex
        className={wrapperClassName}
        flexDirection={['column', null, 'row']}
        width={1}
        // height={height ? height : '100vh'}
      >
        <PolarCircle className={styles.circle} />
        {content?.length > 0 &&
          content.map((blok: any) => (
            <Flex
              key={blok._uid}
              className={styles.highlightWrapper}
              width={1}
              height={['100%', null, 'auto']}
              flex="1"
            >
              {React.createElement(Components(blok.component), {
                key: blok._uid,
                blok: blok,
                className: styles.highlight,
              })}
            </Flex>
          ))}
        {button?.length > 0 && (
          <BigCtaButton className={styles.buttonWrapper} to={button[0].slug}>
            {button[0].title}
          </BigCtaButton>
        )}
      </Flex>
    </SbEditable>
  )
}

export default Hero
