import React from 'react'
import { Box, Flex } from 'reflexbox'
import SbEditable from 'storyblok-react'
import classnames from 'classnames'

import SpotifyPlayer from 'src/storyblok/Components/SpotifyPlayer/SpotifyPlayer'
import YouTubeVideo from 'src/components/YouTubeVideo/YouTubeVideo'
import Row from 'src/components/UI/Grid/Row'
import Column from 'src/components/UI/Grid/Column'
import Markdown from 'src/components/Markdown/Markdown'
import Image from 'src/components/UI/Image/Image'
import LinkComponent from 'src/components/LinkComponent/LinkComponent'

import styles from './TextAndMedia.module.scss'
import { Props } from './types'

const TextAndMedia = ({ blok, gridSpace = 0, imageColumnWidth = 3 }: Props) => {
  const { text, text_position, media } = blok
  const containerWidth = 12 - gridSpace * 2

  return (
    <SbEditable content={blok}>
      <Row justifyContent={['space-between']}>
        <Column
          order={text_position === 'left' ? 0 : 1}
          width={[1, null, null, 4 / containerWidth]}
          marginBottom={text_position === 'left' ? [15, null, null, 0] : 0}
        >
          <Box className={styles.markdown}>
            <Markdown source={text} />
          </Box>
        </Column>
        <Column
          marginBottom={text_position === 'left' ? 0 : [15, null, null, 0]}
          className={styles.mediaSection}
          order={text_position === 'left' ? 1 : 0}
          width={[1, null, null, imageColumnWidth / containerWidth]}
        >
          {media?.length > 0 &&
            media.map((item: any, index: number) => {
              const {
                id,
                component,
                type,
                ratio,
                image,
                youtube_id,
                spotify_uri,
                bleed,
                text,
                source,
              } = item

              let aspectRatio = ''
              if (component === 'image' && ratio) {
                const imageRatio = component === 'image' && ratio ? ratio : ''
                if (imageRatio === '3:2') {
                  aspectRatio = styles.wide
                }
                if (imageRatio === '1:1') {
                  aspectRatio = styles.square
                }
                if (imageRatio === '4:5') {
                  aspectRatio = styles.portrait
                }
              }
              const imageClass = classnames(styles.image, aspectRatio)

              return (
                <React.Fragment key={`${id}_${index}`}>
                  <Flex
                    marginTop={index > 0 ? [11] : 0}
                    flexDirection="column"
                    marginLeft={
                      bleed && text_position === 'right'
                        ? [
                            '-24px',
                            null,
                            null,
                            `calc(-${gridSpace * (1 / 12)}vw - 24px)`,
                          ]
                        : bleed
                        ? ['-24px', null, null, 0]
                        : 0
                    }
                    marginRight={
                      bleed && text_position === 'left'
                        ? [
                            '-24px',
                            null,
                            null,
                            `calc(-${gridSpace * (1 / 12)}vw - 24px)`,
                          ]
                        : bleed
                        ? ['-24px', null, null, 0]
                        : 0
                    }
                    alignItems={
                      bleed && text_position === 'right' ? 'flex-end' : ''
                    }
                  >
                    {component === 'image' && image?.filename && (
                      <Box className={imageClass}>
                        <Image
                          src={image.filename}
                          alt={image.title}
                          sizes={'50vw'}
                        />
                      </Box>
                    )}
                    {component === 'media' &&
                      type === 'youtube' &&
                      youtube_id && (
                        <YouTubeVideo id={youtube_id} image={image} />
                      )}
                    {component === 'spotify_player' && spotify_uri && (
                      <SpotifyPlayer blok={item} />
                    )}

                    <Box
                      as="p"
                      paddingLeft={
                        bleed && text_position === 'right'
                          ? [6, null, null, 8]
                          : bleed
                          ? [6, null, null, 0]
                          : 0
                      }
                      paddingRight={bleed ? [6, null, null, 0] : 0}
                      width={bleed ? [1, null, null, 3 / 5] : 1}
                      marginTop={1}
                      className={styles.mediaText}
                    >
                      {text}
                      {source?.length > 0 && (
                        <span>
                          {` (Source: `}
                          {source[0]?.slug ? (
                            <LinkComponent to={source[0].slug}>
                              {source[0].title}
                            </LinkComponent>
                          ) : (
                            source[0].title
                          )}
                          {`)`}
                        </span>
                      )}
                    </Box>
                  </Flex>
                </React.Fragment>
              )
            })}
        </Column>
      </Row>
    </SbEditable>
  )
}

export default TextAndMedia
