import React, { useState } from 'react'
import SbEditable from 'storyblok-react'
import { Box, Flex } from 'reflexbox'

import Container from 'src/components/UI/Grid/Container'
import Row from 'src/components/UI/Grid/Row'
import Column from 'src/components/UI/Grid/Column'
import Checkbox from 'src/components/UI/Checkbox/Checkbox'
import { ChevronDown } from 'src/components/UI/Elements/Icon'
import LaureatesList from 'src/components/LaureatesList/LaureatesList'
import Components from 'src/storyblok/Components'
import SEO from 'src/components/SEO/SEO'
import { truncateString } from 'src/helpers/truncateString'
import { stripMarkdown } from 'src/helpers/markdown'
import { getSeo } from 'src/helpers/getSeo'

import styles from './PageLaureatesLanding.module.scss'
import { Props } from './types'

const PageLaureatesLanding = ({ blok }: Props) => {
  const { laureates, hero, seo_data } = blok

  // SEO
  const { title, image, markdown }: any = hero?.length > 0 ? hero[0] : {}
  const description = markdown
    ? truncateString(stripMarkdown(markdown), 200)
    : ''
  const { seo_title, seo_description, seo_image } = getSeo(seo_data, {
    description: description ? description : '',
    title: title ? title : '',
    image: image?.filename ? image.filename : '',
  })

  const [filter, setFilter] = useState({
    classical: true,
    pop: true,
  })

  const [select, setSelect] = useState(false)
  const [sortOrder, setSortOrder] = useState('')

  let sortedLaureates =
    laureates?.length > 0
      ? laureates.sort(function (a: any, b: any) {
          if (sortOrder === 'name') {
            if (a.name < b.name) {
              return -1
            }
            if (a.name > b.name) {
              return 1
            }
            return 0
          }
          if (!sortOrder || sortOrder === 'year') {
            return b.year - a.year
          }
        })
      : []

  if (!filter.classical) {
    sortedLaureates = sortedLaureates.filter(
      (item: any) => item.type !== 'classical'
    )
  }
  if (!filter.pop) {
    sortedLaureates = sortedLaureates.filter((item: any) => item.type !== 'pop')
  }

  return (
    <SbEditable content={blok}>
      <SEO
        title={seo_title}
        metaDescription={seo_description}
        image={seo_image}
      />
      {hero &&
        hero.map((blok: any, index: number) =>
          React.createElement(Components(blok.component), {
            key: blok._uid,
            blok: blok,
            index,
          })
        )}
      <Container className={styles.filter}>
        <Row flexDirection="row" alignItems="center" height={['70px']}>
          <Column width={['auto', null, null, 2 / 12]}>
            <Flex
              width="auto"
              className={['hideDesktop', styles.nativeSelect].join(' ')}
              alignItems="center"
              justifyContent="flex-start"
            >
              <select
                className={styles.select}
                onChange={(e: any) => {
                  setSortOrder(e.target.value)
                }}
              >
                <option value="">Sort by</option>
                <option value="year">Year</option>
                <option value="name">Name</option>
              </select>

              <ChevronDown className={styles.chevron} />
            </Flex>
            <Flex
              width="auto"
              className={['hideMobile', styles.customSelect].join(' ')}
              alignItems="center"
              justifyContent="flex-start"
            >
              <button
                className={styles.select}
                onClick={() => {
                  setSelect(!select)
                }}
              >
                <span>{sortOrder ? sortOrder : 'Order by'}</span>
              </button>

              <ChevronDown
                className={[styles.chevron, select ? styles.active : ''].join(
                  ' '
                )}
              />
            </Flex>
            {select && (
              <Flex
                className={styles.selectOptions}
                height={['70px']}
                width={[2 / 12]}
                alignItems="center"
              >
                <button
                  onClick={() => {
                    setSortOrder('year')
                    setSelect(!select)
                  }}
                >
                  <span>Year</span>
                </button>
                <span className={styles.separator}>|</span>
                <button
                  onClick={() => {
                    setSortOrder('name')
                    setSelect(!select)
                  }}
                >
                  <span>Name</span>
                </button>
              </Flex>
            )}
          </Column>
          {/* <Column width="auto">
            <Flex>
              <Box>
                <Checkbox
                  onChange={() => {
                    setFilter({ ...filter, classical: !filter.classical })
                  }}
                  name="classic"
                  checked={filter.classical}
                >
                  Classical
                </Checkbox>
              </Box>
              <Box marginLeft={[5]}>
                <Checkbox
                  onChange={() => {
                    setFilter({ ...filter, pop: !filter.pop })
                  }}
                  name="pop"
                  checked={filter.pop}
                >
                  Pop Music
                </Checkbox>
              </Box>
            </Flex>
          </Column> */}
        </Row>
      </Container>
      <Flex width={1} flexWrap="wrap">
        <LaureatesList laureates={sortedLaureates} gridType="default" />
      </Flex>
    </SbEditable>
  )
}

export default PageLaureatesLanding
