import React, { useState } from 'react'
import { Flex, Box } from 'reflexbox'
import SbEditable from 'storyblok-react'

import Column from 'src/components/UI/Grid/Column'
import Container from 'src/components/UI/Grid/Container'
import Row from 'src/components/UI/Grid/Row'
import useStore from 'src/stores/useStore'
import MemberOverlay from 'src/components/MemberOverlay/MemberOverlay'
import Components from 'src/storyblok/Components'
import Markdown from 'src/components/Markdown/Markdown'

import * as styles from './Members.module.scss'
import { Props } from './types'

const Members = ({ blok, reverse = false }: Props): JSX.Element => {
  const { ui } = useStore()
  const [activeMember, setActiveMember] = useState(null)
  const onMemberClick = (member: any) => {
    setActiveMember(member)
    ui.setMemberActive(true)
  }
  const { title, members, about_title, about_text } = blok
  return (
    <SbEditable content={blok}>
      <MemberOverlay member={activeMember} />
      <Container
        className={styles.memberSection}
        paddingTop={[10]}
        paddingBottom={[10]}
      >
        <Row flexDirection="column" justifyContent="center" alignItems="center">
          <Column width={[1, null, null, 10 / 12]}>
            <Row
              flexDirection={[
                'column',
                null,
                null,
                reverse ? 'row-reverse' : 'row',
              ]}
              justifyContent="space-between"
            >
              {(about_title || about_text) && (
                <Column flexDirection="column" width={[1, null, null, 4 / 10]}>
                  <Box marginBottom={[12]}>
                    {about_title && (
                      <Box marginBottom={[8, null, null, 12]} width={1} as="h3">
                        {about_title}
                      </Box>
                    )}
                    {about_text && <Markdown source={about_text} />}
                  </Box>
                </Column>
              )}
              <Column
                width={[
                  1,
                  null,
                  null,
                  about_title || about_text ? 5 / 10 : 10 / 10,
                ]}
              >
                <Box marginBottom={[7, null, null, 12]} width={1} as="h3">
                  {title}
                </Box>
                <Row flexDirection="row" width={1}>
                  {members?.length > 0 &&
                    members.map((blok: any) => (
                      <Column
                        key={blok._uid}
                        width={[
                          1 / 2,
                          null,
                          null,
                          about_title || about_text ? 2 / 5 : 2 / 10,
                        ]}
                      >
                        <Box marginBottom={[7, null, null, 12]}>
                          {React.createElement(Components(blok.component), {
                            key: blok._uid,
                            blok: blok,
                            onClick: onMemberClick,
                          })}
                        </Box>
                      </Column>
                    ))}
                </Row>
              </Column>
            </Row>
          </Column>
        </Row>
      </Container>
    </SbEditable>
  )
}

export default Members
