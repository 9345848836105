import React from 'react'
import { Box, Flex } from 'reflexbox'
import SbEditable from 'storyblok-react'

import Container from 'src/components/UI/Grid/Container'

import styles from './StoryblokSEO.module.scss'

const StoryblokSEO = ({ blok }: any) => {
  const { seo_title, seo_description, seo_image } = blok

  return (
    <SbEditable content={blok}>
      <Container className={styles.wrapper}>
        <Flex
          width={1}
          flexDirection={['column', null, null, 'row']}
          paddingTop={[20]}
          paddingBottom={[15]}
        >
          <Flex flexDirection="column" width={[1, null, null, 8 / 12]}>
            <Box className={styles.label}>Title</Box>
            <Box marginBottom={[3]} className={styles.title} as="h1">
              {seo_title}
            </Box>
          </Flex>
          <Flex flexDirection="column" width={[1, null, null, 4 / 12]}>
            <Box marginBottom={2} className={styles.label}>
              Description
            </Box>
            <Box className={styles.preamble} as="p" marginBottom={7}>
              {seo_description}
            </Box>
            {seo_image?.filename && (
              <React.Fragment>
                <Box marginBottom={2} className={styles.label}>
                  Share image
                </Box>
                <Box className={styles.imageWrapper}>
                  <img className={styles.image} src={seo_image.filename} />
                </Box>
              </React.Fragment>
            )}
          </Flex>
        </Flex>
      </Container>
    </SbEditable>
  )
}

export default StoryblokSEO
