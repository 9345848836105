import React from 'react'
import { Flex } from 'reflexbox'
import SbEditable from 'storyblok-react'
import { format } from 'date-fns'
import orderBy from 'lodash/orderBy'

import { truncateString } from 'src/helpers/truncateString'
import { stripMarkdown } from 'src/helpers/markdown'
import { getSeo } from 'src/helpers/getSeo'
import Components from 'src/storyblok/Components'
import SEO from 'src/components/SEO/SEO'
import VideoList from 'src/components/VideosList/VideoList'

import { Props } from './types'
// import styles from './PageVideosLanding.module.scss'

const sortByYears = (videos) => {
  // Group videos by year
  const videoGroup = videos.reduce((group, video) => {
    const year = video?.date ? format(new Date(video.date), 'yyyy') : ''
    group[year]
      ? group[year].videos.push(video)
      : (group[year] = { label: year, videos: [video] })
    return group
  }, {})

  // Put group objects in array with label and events
  const videosGroupByYear = Object.keys(videoGroup).map(function (k) {
    return videoGroup[k]
  })

  // Order groups by year
  return orderBy(videosGroupByYear, ['label'], ['desc'])
}

const PageVideosLanding = ({ blok }: Props) => {
  const { hero, videos, seo_data } = blok

  // SEO
  const { title, image, markdown }: any = hero?.length > 0 ? hero[0] : {}
  const description = markdown
    ? truncateString(stripMarkdown(markdown), 200)
    : ''
  const { seo_title, seo_description, seo_image } = getSeo(seo_data, {
    description: description ? description : '',
    title: title ? title : '',
    image: image?.filename ? image.filename : '',
  })

  const groupedVideos = videos?.length > 0 ? sortByYears(videos) : []

  return (
    <SbEditable content={blok}>
      <Flex flexDirection="column" width={1}>
        <SEO
          title={seo_title}
          metaDescription={seo_description}
          image={seo_image}
        />
        {hero &&
          hero.map((blok: any) =>
            React.createElement(Components(blok.component), {
              key: blok._uid,
              blok: blok,
            })
          )}
        {groupedVideos?.length > 0 &&
          groupedVideos.map((group) => (
            <VideoList
              key={group.label}
              title={group.label}
              videos={group.videos}
              ctaArea={false}
            />
          ))}
      </Flex>
    </SbEditable>
  )
}

export default PageVideosLanding
