import React from 'react'
import { Flex } from 'reflexbox'
import SbEditable from 'storyblok-react'

import { truncateString } from 'src/helpers/truncateString'
import { stripMarkdown } from 'src/helpers/markdown'
import { getSeo } from 'src/helpers/getSeo'
import Components from 'src/storyblok/Components'
import SEO from 'src/components/SEO/SEO'
import VideoList from 'src/components/VideosList/VideoList'

import { Props } from './types'
// import styles from './PageTalksLanding.module.scss'

const PageTalksLanding = ({ blok }: Props) => {
  const { hero, content, video_title, videos, seo_data } = blok

  // SEO
  const { title, image, markdown }: any = hero?.length > 0 ? hero[0] : {}
  const description = markdown
    ? truncateString(stripMarkdown(markdown), 200)
    : ''
  const { seo_title, seo_description, seo_image } = getSeo(seo_data, {
    description: description ? description : '',
    title: title ? title : '',
    image: image?.filename ? image.filename : '',
  })

  return (
    <SbEditable content={blok}>
      <Flex flexDirection="column" width={1}>
        <SEO
          title={seo_title}
          metaDescription={seo_description}
          image={seo_image}
        />
        {hero &&
          hero.map((blok: any) =>
            React.createElement(Components(blok.component), {
              key: blok._uid,
              blok: blok,
            })
          )}
        {content &&
          content.map((blok: any) =>
            React.createElement(Components(blok.component), {
              key: blok._uid,
              blok: blok,
            })
          )}
        <VideoList title={video_title} videos={videos} />
      </Flex>
    </SbEditable>
  )
}

export default PageTalksLanding
