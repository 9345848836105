import React, { useState } from 'react'
import { Box, Flex } from 'reflexbox'
import YouTube from 'react-youtube'

import Image from 'src/components/UI/Image/Image'
import { PlayIcon } from 'src/components/UI/Elements/Icon'

import styles from './YouTubeVideo.module.scss'
import { Props } from './types'

const YouTubeVideo = ({ id, image }: Props) => {
  const [videoActive, setVideoActive] = useState(false)
  let videoTarget: any = null

  const opts = {
    height: '100%',
    width: '100%',
  }

  const onVideoReady = (e: any) => {
    videoTarget = e.target
  }

  const onClick = () => {
    setVideoActive(true)
    if (videoTarget) {
      videoTarget.playVideo()
    }
  }

  return (
    <Box className={styles.videoWrapper} width={1}>
      {!videoActive && (
        <Flex
          alignItems="center"
          justifyContent="center"
          onClick={onClick}
          className={styles.poster}
        >
          <PlayIcon className={styles.playButton} />
          {image && image.filename ? (
            <Image src={image.filename} sizes={'100vw'} alt={image.title} />
          ) : (
            <img
              src={`https://img.youtube.com/vi/${id}/0.jpg`}
              alt={image.title}
            />
          )}
        </Flex>
      )}
      <YouTube onReady={onVideoReady} opts={opts} videoId={id} />
    </Box>
  )
}

export default YouTubeVideo
