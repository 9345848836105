import React from 'react'
import SbEditable from 'storyblok-react'

import { truncateString } from 'src/helpers/truncateString'
import { stripMarkdown } from 'src/helpers/markdown'
import { getSeo } from 'src/helpers/getSeo'
import ShareSection from 'src/components/ShareSection/ShareSection'
import HalfTextHero from 'src/storyblok/Modules/HalfTextHero/HalfTextHero'
import SEO from 'src/components/SEO/SEO'

import { Props } from './types'

const PageArticle = ({ blok }: Props) => {
  const { title, content, media: image, seo_data } = blok

  // SEO
  const description = content ? truncateString(stripMarkdown(content), 200) : ''
  const { seo_title, seo_description, seo_image } = getSeo(seo_data, {
    description: description ? description : '',
    title: title ? title : '',
    image: image?.filename ? image.filename : '',
  })

  return (
    <SbEditable content={blok}>
      <SEO
        title={seo_title}
        metaDescription={seo_description}
        image={seo_image}
      />

      <HalfTextHero blok={{ title, image, markdown: content }} />
      <ShareSection />
    </SbEditable>
  )
}

export default PageArticle
