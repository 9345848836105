import React from 'react'

type Icon = {
  color?: string
  className?: string
  width?: number
  height?: number
  strokeWidth?: number
}

export const PlayIcon = ({
  color = '#ffffff',
  className,
  width = 147,
  height = 274,
  strokeWidth = 3,
}: Icon) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 147 274"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M143 137L7 59V215L143 137Z"
      stroke={color}
      strokeWidth={strokeWidth}
    />
  </svg>
)

export const HamburgerIcon = ({
  color = '#ffffff',
  className,
  width = 27,
  height = 22,
}: Icon) => (
  <svg
    className={className}
    width={width}
    height={height}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 27 22"
  >
    <path
      d="M0 1h26.785M0 6h26.785M0 11h26.785M0 16h26.785M0 21h26.785"
      stroke={color}
    />
  </svg>
)

export const CloseIcon = ({
  color = '#ffffff',
  className,
  width = 21,
  height = 21,
}: Icon) => (
  <svg
    className={className}
    width={width}
    height={height}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 21 21"
  >
    <path d="M.923 1l18.94 18.94M.923 19.47L19.863.53" stroke={color} />
  </svg>
)

export const ChevronDown = ({
  color = '#ffffff',
  className,
  width = 14,
  height = 6,
}: Icon) => (
  <svg
    fill="none"
    className={className}
    width={width}
    height={height}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 15 8"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.5 6L14.175.279l.65.76-7 6-.325.278-.325-.279-7-6 .65-.76L7.5 6z"
      fill={color}
    />
  </svg>
)

export const ArrowLeft = ({
  color = '#ffffff',
  className,
  width = 45,
  height = 45,
}: Icon) => (
  <svg
    width={width}
    height={height}
    className={className}
    viewBox="0 0 45 45"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M39.375 22L7.73438 22" stroke={color} strokeWidth="1.40625" />
    <path
      d="M15.4688 31.8438L7.03125 22L15.4688 12.1562"
      stroke={color}
      strokeWidth="1.40625"
    />
  </svg>
)

export const ArrowRight = ({
  color = '#ffffff',
  className,
  width = 45,
  height = 45,
}: Icon) => (
  <svg
    width={width}
    height={height}
    className={className}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 25 16"
  >
    <path d="M.125 8h23.203M17.656.781L23.844 8l-6.188 7.219" stroke={color} />
  </svg>
)

export const ArrowDown = ({
  color = '#000000',
  className,
  width = 22,
  height = 22,
}: Icon) => (
  <svg
    width={width}
    height={height}
    className={className}
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.7877 17.3269L10.7877 2.90088L11.4461 2.90088L11.4461 17.3269L15.511 13.8426L15.9395 14.3425L11.3311 18.2925L11.1169 18.4761L10.9027 18.2925L6.29435 14.3425L6.72279 13.8426L10.7877 17.3269Z"
      fill={color}
    />
  </svg>
)

export const ArrowTopRight = ({
  color = '#000000',
  className,
  width = 15,
  height = 15,
}: Icon) => (
  <svg
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 15 15"
    width={width}
    height={height}
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.425 1.649h-8.48v-1h10.187v10.187h-1v-8.48L1.354 14.134l-.708-.707L12.425 1.65z"
      fill={color}
    />
  </svg>
)

export const QuoteIcon = ({
  color = '#E4D9A9',
  className,
  width = 51,
  height = 26,
}: Icon) => (
  <svg
    width={width}
    height={height}
    className={className}
    viewBox="0 0 41 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.2586 0.3125H0V0.812501L16.2586 0.8125V0.3125ZM40.9998 0.3125H24.7412V0.812501L40.9998 0.8125V0.3125ZM40.9998 5L24.7412 5V5.5L40.9998 5.5V5ZM24.7412 10L40.9998 10V10.5L24.7412 10.5V10ZM40.9998 15L24.7412 15V15.5L40.9998 15.5V15ZM24.7412 20H40.9998V20.5H24.7412V20ZM0 5L16.2586 5V5.5L0 5.5V5ZM16.2586 10L0 10V10.5L16.2586 10.5V10ZM0 15L16.2586 15V15.5L0 15.5V15ZM16.2586 20H0V20.5H16.2586V20Z"
      fill="#E4D9A9"
    />
  </svg>
)

export const TuneIcon = ({
  color = '#000',
  className,
  width = 16,
  height = 16,
}: Icon) => (
  <svg
    width={width}
    height={height}
    className={className}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
  >
    <g style={{ mixBlendMode: 'multiply' }}>
      <path
        d="M5.05 2.149l10.9-1.107V12a2.95 2.95 0 11-.983-2.199l.083.075V4.988l-.056.006-9 .993-.044.005V13a2.95 2.95 0 11-.983-2.199l.083.075V2.149zm.945.81l-.045.004V5.086l.055-.006 9-.993.045-.005V2.038l-.055.006-9 .914zm-.945 10.04a2.05 2.05 0 10-4.1 0 2.05 2.05 0 004.1 0zm10-1a2.05 2.05 0 10-4.1 0 2.05 2.05 0 004.1 0z"
        fill={color}
      />
      <path
        d="M5.05 2.149l10.9-1.107V12a2.95 2.95 0 11-.983-2.199l.083.075V4.988l-.056.006-9 .993-.044.005V13a2.95 2.95 0 11-.983-2.199l.083.075V2.149zm.945.81l-.045.004V5.086l.055-.006 9-.993.045-.005V2.038l-.055.006-9 .914zm-.945 10.04a2.05 2.05 0 10-4.1 0 2.05 2.05 0 004.1 0zm10-1a2.05 2.05 0 10-4.1 0 2.05 2.05 0 004.1 0z"
        stroke="#fff"
        strokeWidth=".1"
      />
    </g>
  </svg>
)

export const SpotifyIcon = ({
  color = '#000',
  className,
  width = 20,
  height = 20,
}: Icon) => (
  <svg
    width={width}
    height={height}
    className={className}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
  >
    <path
      d="M9.991 0C4.496 0 0 4.496 0 9.991c0 5.494 4.496 9.99 9.991 9.99 5.494 0 9.99-4.496 9.99-9.99C19.981 4.496 15.535 0 9.991 0zm4.595 14.436c-.199.299-.549.4-.85.201-2.349-1.45-5.296-1.75-8.793-.951-.348.102-.648-.148-.748-.449-.101-.35.149-.648.45-.749 3.795-.85 7.093-.499 9.69 1.1.35.149.4.548.251.848zm1.2-2.747c-.251.349-.7.499-1.051.249-2.697-1.646-6.792-2.148-9.939-1.148-.398.101-.85-.1-.949-.498-.101-.402.1-.852.499-.952 3.646-1.098 8.143-.548 11.239 1.351.3.149.45.648.201.998zm.099-2.799c-3.197-1.897-8.542-2.097-11.59-1.146a.938.938 0 01-1.148-.6.937.937 0 01.599-1.151c3.547-1.049 9.392-.85 13.089 1.351.449.249.599.849.349 1.298-.25.35-.849.498-1.299.248z"
      fill={color}
    />
  </svg>
)
