import React from 'react'
import { Box } from 'reflexbox'
import SbEditable from 'storyblok-react'
import classnames from 'classnames'

import Components from 'src/storyblok/Components'
import { stringToSlug } from 'src/helpers/stringToSlug'
import Container from 'src/components/UI/Grid/Container'
import Row from 'src/components/UI/Grid/Row'
import Column from 'src/components/UI/Grid/Column'

import styles from './Chapter.module.scss'
import { Props } from './types'

const Chapter = ({ blok, index }: Props) => {
  const { title, modules, background } = blok
  let fullWidthFirst = false
  let bleedFirst = false
  if (
    (modules && modules[0] && modules[0]?.component === 'full_width_image') ||
    (modules && modules[0] && modules[0]?.component === 'quote')
  ) {
    fullWidthFirst = true
  }
  if (modules && modules[0] && modules[0].component === 'text_and_media') {
    if (
      modules[0].images &&
      modules[0].images[0] &&
      modules[0].images[0].bleed
    ) {
      bleedFirst = true
    }
  }

  const gridWidth = 8 / 12

  const wrapperClassName = classnames(
    styles.wrapper,
    background === 'black' ? styles.black : styles.white
  )

  return (
    <SbEditable content={blok}>
      <Container className={wrapperClassName}>
        <Row
          as="section"
          className="chapter-section"
          data-index={index}
          id={stringToSlug(title)}
          flexDirection="column"
          paddingTop={
            fullWidthFirst
              ? 0
              : bleedFirst
              ? [0, null, null, 28]
              : [13, null, null, 28]
          }
          justifyContent="center"
          alignItems="center"
        >
          {modules &&
            modules.map((blok: any) => (
              <Column
                key={blok._uid}
                width={
                  blok.component === 'full_width_image' ||
                  blok.component === 'quote'
                    ? 1
                    : [1, null, null, gridWidth]
                }
                marginBottom={[13, null, null, 28]}
              >
                <Box
                  marginX={
                    blok.component === 'full_width_image' ||
                    blok.component === 'quote'
                      ? ['-24px', null, null, '-24px']
                      : 0
                  }
                >
                  {React.createElement(Components(blok.component), {
                    key: blok._uid,
                    blok: blok,
                    gridSpace: 2,
                    gridWidth: gridWidth,
                  })}
                </Box>
              </Column>
            ))}
        </Row>
      </Container>
    </SbEditable>
  )
}

export default Chapter
