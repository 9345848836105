// Page types
import PageStart from './Pages/PageStart/PageStart'
import PageLaureate from './Pages/PageLaureate/PageLaureate'
import PageLaureatesLanding from './Pages/PageLaureatesLanding/PageLaureatesLanding'
import PageAbout from './Pages/PageAbout/PageAbout'
import PageArticle from './Pages/PageArticle/PageArticle'
import PageTalks from './Pages/PageTalks/PageTalks'
import PageTalksLanding from './Pages/PageTalksLanding/PageTalksLanding'
import PagePress from './Pages/PagePress/PagePress'
import PageDefault from './Pages/PageDefault/PageDefault'
import PageVideosLanding from './Pages/PageVideosLanding/PageVideosLanding'

// Global Modules
import Header from './GlobalModules/Header/Header'
import Footer from './GlobalModules/Footer/Footer'

// Components
import ComponentNotFound from './ComponentNotFound'
import StoryblokSEO from './Components/StoryblokSEO/StoryblokSEO'
import Textarea from './Components/Textarea/Textarea'
import HeroContent from './Components/HeroContent/HeroContent'
import Member from './Components/Member/Member'
import SpotifyPlayer from './Components/SpotifyPlayer/SpotifyPlayer'

// Modules
import Chapter from './Modules/Chapter/Chapter'
import TextAndMedia from './Modules/TextAndMedia/TextAndMedia'
import Media from './Modules/Media/Media'
import FullWidthImage from './Modules/FullWidthImage/FullWidthImage'
import Hero from './Modules/Hero/Hero'
import HalfTextHero from './Modules/HalfTextHero/HalfTextHero'
import Banner from './Modules/Banner/Banner'
import Quote from './Modules/Quote/Quote'
import PressArtist from './Modules/PressArtist/PressArtist'
import Highlights from './Modules/Highlights/Highlights'
import Video from './Modules/Video/Video'
import ImageModule from './Modules/ImageModule/ImageModule'
import LaureatesGrid from './Modules/LaureatesGrid/LaureatesGrid'
import ArticlesList from './Modules/ArticlesList/ArticlesList'
import NominationForm from './Modules/NominationForm/NominationForm'
import Members from './Modules/Members/Members'
import TitleAndText from './Modules/TitleAndText/TitleAndText'
import LinkList from './Modules/LinkList/LinkList'

const ComponentList: any = {
  // Page types
  page_start: PageStart,
  page_laureate: PageLaureate,
  page_laureates_landing: PageLaureatesLanding,
  page_about: PageAbout,
  page_article: PageArticle,
  page_talks: PageTalks,
  page_talks_landing: PageTalksLanding,
  page_press: PagePress,
  page_default: PageDefault,
  page_videos_landing: PageVideosLanding,

  // Global Modules
  header: Header,
  footer: Footer,

  // Components
  seo: StoryblokSEO,
  textarea: Textarea,
  hero_content: HeroContent,
  member: Member,
  spotify_player: SpotifyPlayer,

  // Modules
  chapter: Chapter,
  text_and_media: TextAndMedia,
  media: Media,
  full_width_image: FullWidthImage,
  hero: Hero,
  half_text_hero: HalfTextHero,
  banner: Banner,
  quote: Quote,
  press_artist_downloads: PressArtist,
  highlights: Highlights,
  video: Video,
  image: ImageModule,
  laureates_grid: LaureatesGrid,
  articles_list: ArticlesList,
  nomination_form: NominationForm,
  members: Members,
  title_and_text: TitleAndText,
  link_list: LinkList,
}

const Components = (type: string) => {
  if (typeof ComponentList[type] === 'undefined') {
    return ComponentNotFound
  }
  return ComponentList[type]
}

export default Components
