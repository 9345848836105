import React from 'react'
import { Flex, Box } from 'reflexbox'
import SbEditable from 'storyblok-react'

import { truncateString } from 'src/helpers/truncateString'
import { stripMarkdown } from 'src/helpers/markdown'
import { getSeo } from 'src/helpers/getSeo'
import Image from 'src/components/UI/Image/Image'
import Container from 'src/components/UI/Grid/Container'
import Column from 'src/components/UI/Grid/Column'
import Markdown from 'src/components/Markdown/Markdown'
import Components from 'src/storyblok/Components'
import Row from 'src/components/UI/Grid/Row'
import SEO from 'src/components/SEO/SEO'
import LinkComponent from 'src/components/LinkComponent/LinkComponent'
import { StoryblokLink } from 'src/globalTypes/types'

import { Props } from './types'
import styles from './PagePress.module.scss'

const PageAbout = ({ blok }: Props) => {
  const {
    seo_data,
    hero,
    content,
    logotypes,
    laureate_downloads,
    articles,
    social_links,
    address_text,
    contact_title,
    contact_persons,
  } = blok

  // SEO
  const { title, image, markdown }: any = hero?.length > 0 ? hero[0] : {}
  const description = markdown
    ? truncateString(stripMarkdown(markdown), 200)
    : ''
  const { seo_title, seo_description, seo_image } = getSeo(seo_data, {
    description: description ? description : '',
    title: title ? title : '',
    image: image?.filename ? image.filename : '',
  })

  const sortedArticles =
    articles && articles.length > 0
      ? articles.sort(function (a: any, b: any) {
          return new Date(b.date) - new Date(a.date)
        })
      : []

  return (
    <SbEditable content={blok}>
      <SEO
        title={seo_title}
        metaDescription={seo_description}
        image={seo_image}
      />
      {hero &&
        hero.map((blok: any) =>
          React.createElement(Components(blok.component), {
            key: blok._uid,
            blok: blok,
          })
        )}

      {content &&
        content.map((blok: any) =>
          React.createElement(Components(blok.component), {
            key: blok._uid,
            blok: blok,
            articles:
              blok?.component === 'articles_list' && sortedArticles?.length > 0
                ? sortedArticles.slice(0, 3)
                : [],
          })
        )}
      <Container className={styles.logoSection} paddingY={[11, null, null, 26]}>
        <Row justifyContent="center">
          <Column
            marginBottom={[11, null, null, 20]}
            width={[1, null, null, 10 / 12]}
          >
            <Box as="h2">{'Polar Music Prize Logotypes'}</Box>
          </Column>
          {logotypes &&
            logotypes.length > 0 &&
            logotypes.map((logo: any) => (
              <Column
                key={logo._uid}
                marginBottom={[8]}
                className={styles.item}
                width={[1, null, null, 5 / 12]}
              >
                {logo && logo.file && logo.file.filename && (
                  <a href={logo.file.filename} target="_blank">
                    <Box className={styles.asset}>
                      <Image
                        src={logo.file.filename}
                        sizes={'40vw'}
                        alt={logo.explanation_text}
                      />
                    </Box>
                    <Box className={styles.explanation} marginTop={3} as="span">
                      {logo.explanation_text}
                    </Box>
                  </a>
                )}
              </Column>
            ))}
        </Row>
      </Container>
      <Container
        className={styles.artistSection}
        paddingTop={[11, null, null, 24]}
        paddingBottom={[0, null, null, 14]}
      >
        <Row justifyContent="center">
          <Flex
            flexWrap="wrap"
            justifyContent="space-between"
            width={[1, null, null, 10 / 12]}
          >
            {laureate_downloads &&
              laureate_downloads.map((blok: any) => (
                <Column
                  key={blok._uid}
                  marginBottom={[11, null, null, 20]}
                  width={[1, null, null, 4 / 10]}
                >
                  {React.createElement(Components(blok.component), {
                    key: blok._uid,
                    blok: blok,
                  })}
                </Column>
              ))}
          </Flex>
        </Row>
      </Container>
      <Container
        paddingTop={[11, null, null, 24]}
        paddingBottom={[3, null, null, 12]}
        className={styles.contactSection}
      >
        <Row justifyContent="center">
          <Column width={[1, null, null, 10 / 12]}>
            <Row>
              <Column
                width={[1, null, null, 4 / 10]}
                marginBottom={[8, null, null, 0]}
              >
                {contact_title && <Box as="h3">{contact_title}</Box>}
              </Column>
              <Column width={[1, null, null, 6 / 10]}>
                <Row>
                  {contact_persons &&
                    contact_persons.length > 0 &&
                    contact_persons.map((person: any) => {
                      const image = person?.image?.filename
                        ? person.image.filename
                        : ''
                      return (
                        <SbEditable content={person} key={person._uid}>
                          <Column
                            paddingX={[0, null, null, 3]}
                            width={[1, null, null, image ? 6 / 6 : 3 / 6]}
                            flexDirection="column"
                            marginBottom={[8, null, null, 12]}
                          >
                            <Flex>
                              {image && (
                                <Box
                                  width={2 / 3}
                                  className="hideMobile"
                                  marginRight={[6]}
                                >
                                  <Box className={styles.contactImage}>
                                    <Image
                                      src={image}
                                      alt={person.name}
                                      sizes={'33vw'}
                                    />
                                  </Box>
                                </Box>
                              )}
                              <Box
                                width={[1, null, null, image ? 2 / 3 : 3 / 3]}
                              >
                                <Box
                                  className={styles.contactLabel}
                                  marginBottom={[2, null, null, 6]}
                                  width={1}
                                  as="span"
                                >
                                  {person.name}
                                </Box>
                                <Markdown
                                  source={person.title_and_contact_info}
                                />
                              </Box>
                            </Flex>
                          </Column>
                        </SbEditable>
                      )
                    })}
                </Row>
                <Row>
                  <Column
                    paddingX={[0, null, null, 3]}
                    marginBottom={[8, null, null, 12]}
                    width={[1, null, null, 3 / 6]}
                  >
                    <Box
                      className={styles.contactLabel}
                      marginBottom={[2, null, null, 6]}
                      width={1}
                      as="span"
                    >
                      {'Postal Address'}
                    </Box>
                    <Markdown source={address_text} />
                  </Column>
                  <Column
                    paddingX={[0, null, null, 3]}
                    width={[1, null, null, 3 / 6]}
                  >
                    <Box
                      className={styles.contactLabel}
                      marginBottom={[2, null, null, 8]}
                      width={1}
                      as="span"
                    >
                      {'Social Media'}
                    </Box>
                    {social_links &&
                      social_links.length > 0 &&
                      social_links.map(
                        ({ title, slug, _uid }: StoryblokLink) => (
                          <Box
                            className={styles.socialLink}
                            width={1}
                            key={_uid}
                          >
                            <LinkComponent to={slug}>{title}</LinkComponent>
                          </Box>
                        )
                      )}
                  </Column>
                </Row>
              </Column>
            </Row>
          </Column>
        </Row>
      </Container>
    </SbEditable>
  )
}

export default PageAbout
