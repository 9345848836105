export const getTitleParts = (name: string) => {
  const nameParts = name?.split(' ')?.length > 0 ? name.split(' ') : []
  let namePartGroups = []

  if (nameParts?.length > 2) {
    const groupChunk = 2
    let count = 0
    namePartGroups = name
      ?.split(' ')
      .map(() => {
        const chunk = nameParts.slice(count, count + groupChunk)

        count = count + groupChunk
        return chunk.length > 0 ? chunk : null
      })
      .filter((group) => group)
  } else {
    namePartGroups = nameParts
  }

  return namePartGroups
}
