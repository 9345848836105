import React from 'react'
import { Box, Flex } from 'reflexbox'
import SbEditable from 'storyblok-react'
import classnames from 'classnames'

import Markdown from 'src/components/Markdown/Markdown'
import Image from 'src/components/UI/Image/Image'

import styles from './HeroContent.module.scss'
import { Props } from './types'

const HeroContent = ({ blok, className }: Props) => {
  const {
    title,
    markdown,
    media,
    mobile_image,
    align_title,
    align_title_horizontal,
    use_filter,
  } = blok
  const wrapperClassNames = classnames(
    styles.wrapper,
    use_filter ? styles.withFilter : '',
    className
  )

  return (
    <SbEditable content={blok}>
      <Box
        className={wrapperClassNames}
        width={1}
        height={['auto', null, null, '100%']}
      >
        <Flex
          paddingY={[6, null, null, 12]}
          paddingLeft={[6, null, null, 15]}
          paddingRight={[6, null, null, 15]}
          className={styles.content}
          width={1}
          height="100%"
          flexDirection="column"
          justifyContent={align_title === 'top' ? 'flex-start' : 'flex-end'}
          alignItems={
            align_title_horizontal === 'right' ? 'flex-end' : 'flex-start'
          }
        >
          <Box width={[1, null, null, 10 / 12]}>
            <Box
              className={styles.title}
              as="h1"
              textAlign={
                align_title_horizontal === 'right'
                  ? ['right', null, null, 'right']
                  : ['left', null, null, 'left']
              }
            >
              {markdown ? <Markdown source={markdown} /> : title ? title : ''}
            </Box>
          </Box>
        </Flex>
        {media?.filename && (
          <Box className={styles.image}>
            <Image
              src={media.filename}
              className={mobile_image?.filename ? 'hideMobile' : ''}
              sizes={{
                mobile: '80vw',
                tablet: '100vw',
                desktop: '50vw',
              }}
              alt="title"
            />
            {mobile_image?.filename && (
              <Image
                src={mobile_image.filename}
                className="hideDesktop"
                sizes={{
                  mobile: '80vw',
                  tablet: '100vw',
                  desktop: '50vw',
                }}
                alt="title"
              />
            )}
          </Box>
        )}
      </Box>
    </SbEditable>
  )
}

export default HeroContent
